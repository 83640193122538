import React, {Component} from 'react'
import classNames from 'classnames'

import './Steps.scss'

const Step = ({index, current, step: {title}}) => (
  <div className="level">
    <div
      className={
        classNames(
          'level-circle',
          {current: current === index},
          {complete: current > index}
        )
      }
    >
      <span>{index + 1}</span>
    </div>
    <p>{title}</p>
  </div>
)

export default class Steps extends Component {

  state = {
    steps: [],
    current: 0
  }

  componentDidMount() {
    const {steps, current} = this.props
    this.setState({steps, current})
  }

  setCurrent = current => this.setState({current})

  render () {
    const {current, steps} = this.props
    return (
      <div className="levels">
        {
          steps.map((step, index) =>
            (<Step {...{key: step.title + index, step, current, index}}/>))
        }
      </div>
    )
  }
}
